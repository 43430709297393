








































import { Component, Vue, Watch } from 'vue-property-decorator';
import * as lib from '@/scripts/apiHelper.ts';
import { ApiProfile } from '../scripts/apiProfile';

@Component
export default class Activate extends Vue {
  private validatedOK = false;
  private cancelled = true;

  private error = '';

  // Watch for link code in URL
  @Watch('$route.query.linkcode', { immediate: true })
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private async activate(newValue: string, oldValue: string): Promise<void> {
    // Get link code from url
    if (newValue) {
      try {
        this.error = '';
        const result = await ApiProfile.activate({ LinkCode: newValue });
        this.validatedOK = result.Result;
        this.cancelled = false;
      } catch (error) {
        this.error = lib.getErrorMessage(error);
      }
    }
  }
}
